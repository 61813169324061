.meeting-calendar {
    .calendar-header {
        align-items: center;
        padding: 10px;
    }

    .count {
        margin-left: 10px;
    }

    .month-picker {
        text-align: right;

        &-text {
            font-weight: bold;
            font-size: 16px;
        }

        .ant-btn {
            border: none;
            color: black;
        }
    }

    .ant-picker-panel {
        border-top: 1px solid #f0f0f0;
    }

    .hojokin-alert {
        background-color: #fffbe6;
        border: 1px solid #ffe58f;
    }

    .josekin-alert {
        background-color: #f8a5c2;
        border: 1px solid #f78fb3;
    }

    .kisoku-alert {
        background-color: #b8e994;
        border: 1px solid #78e08f;
    }

    .meeting-notifications-container .ant-alert-message > a {
        color: #000;
    }
}