body#dashboard {
    @import 'src/assets/styles/mobile/components/consultation';
    @import 'src/assets/styles/mobile/components/meeting_calendar';

    .ant-layout-content {
        margin-left: 0;
    }

    .ant-layout-header {
        margin-left: 0;
        width: 100%;
    }

    .ant-layout-sider {
        z-index: 1000;
    }

    .dashboard-content.profile {
        .form-container {
            width: 100%;
        }
    }

    .header-container {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .page-actions {
        margin-left: 0;
    }

    .header-container {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .page-actions {
        margin-left: 0;
    }
}
