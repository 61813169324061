@import '../variables';

body {
    margin: 0;
    font-family: 'Noto Sans JP', sans-serif;
}

.loader {
    background: url('../../images/loader.gif') rgba(255, 255, 255, 0.5) center no-repeat;
    background-size: 7%;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    transition: .3s all ease-in-out;
    opacity: 1;
}

.helper-text {
    font-size: 12px;
    color: #747272;
}

.fade-enter {
    opacity: 0;
}

.fade-exit {
    opacity: 1;
}

.fade-enter-active {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
    transition: opacity 300ms;
}

.ant-btn, .ant-modal-content, .ant-input {
    border-radius: $border-radius !important;
}

.ant-input[readonly] {
    background: #F5F5F5;
}

.link {
    text-decoration: underline;
    cursor: pointer;
}

.breadcrumbs {
    padding: 60px 182px 30px 182px;
}

.login-card,
.success-modal,
.forgot-password-modal,
.forgot-password-card,
.registration-modal,
.diagnose-submit-modal,
.custom-confirmation-modal {
    width: 600px !important;
}

.login-card {
    margin-top: 50px;
}

.eligibility-modal {
    width: 800px !important;
}

.login-card,
.success-modal,
.forgot-password-modal,
.forgot-password-card,
.registration-modal,
.consultation-decline-modal,
.consultation-cancel-modal,
.change-password-modal,
.submit-schedule-modal,
.custom-confirmation-modal,
.kisoku-meetings-modal {
    .ant-modal-body {
        padding: 50px 13%;
    }

    .title {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 15px;
        text-align: center;
    }

    .subtitle {
        font-size: 14px;
        text-align: center;
        color: $gray-6;
    }

    form {
        margin: 35px auto;
        width: 300px;

        .link {
            display: block;
            margin: 20px 0;
            font-size: 13px;
            color: $gray-6;
        }
    }
}

//Display first error message only in rules validation
.submit-schedule-content {
    form div.ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
        display: none;
    }
}

button.ant-btn-primary {
    background-color: $button-primary-color;
    color: #FFFFFF;
}

.ant-btn:not(.ant-btn-dangerous, .ant-btn-primary, [disabled]) {
    background-color: $button-secondary-color;
    color: #1890FF;
    border: 1px solid #1890FF;
}

.success-modal {
    text-align: center;
    align-content: center;

    .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }

    img {
        margin: 30px;
    }

    .message {
        margin: 10px 10px;
        white-space: pre-line;
    }
}

.file-uploader > .ant-upload {
    width: 100%;
    height: 120px;
}

.required-mark:before {
    content: '* ';
    color: $red-3;
}

/**
   Reusable custom spacing and size classes
 */
@each $width in 100px, 200px, 300px {
    .w-#{$width} {
        width: $width;
    }
}

.my-30px {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.line-height-35px {
    line-height: 35px;
}

.table-row-gray {
    background: $gray-3;
}

/**
   Reusable custom color classes
 */
$colors: (
    light-blue-3: $light-blue-3
);

@each $className, $color in $colors {
    .#{$className} {
        color: $color !important;
    }
}