
.consultation-list {
    .table-filter {
        position: inherit;
        margin-bottom: 20px;
    }

    .status-dropdown {
        width: 48%;
    }

    .search-dropdown {
        width: 48%;
    }
}

.consultation-details {
    .action-buttons {
        &_cancel {
            margin-right: 20px;
            width: 45%;
        }

        &_send {
            width: 45%;
        }
    }
}

.submit-schedule-modal {
    padding-left: 0;
}

.consultation-cancel-modal {
    button.modal-button {
        margin: 5px 0;
    }

    form {
        width: 100% !important;
    }

    .form-buttons {
        width: 100%;
        margin: auto;
    }
}

.consultation-list-card {
    .details-button {
        width: 100%;
    }
}

