.diagnose {
    .file-action-table-button-icons {
        font-size: 1.1em;
    }

    .file-history-list {
        list-style-type: none;
        padding-inline-start: 0;
    }

    .comment-info {
        color:$gray-4
    }
}

.diagnose-submit-modal {
    .upload-list {
        margin-bottom: 10px;
        font-weight: bold;

        .upload-item {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
        }

        .anticon-delete {
            font-size: 18px;
            cursor: pointer;
        }
    }
}
