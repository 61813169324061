@import 'src/assets/styles/variables';

body#dashboard {
    @import 'src/assets/styles/desktop/components/registration_card';
    @import 'src/assets/styles/desktop/components/meeting_calendar';
    @import 'src/assets/styles/desktop/components/consultation';
    @import 'src/assets/styles/desktop/components/diagnose';

    background: $white;

    .ant-layout {
        min-height: 100vh;
    }

    .down-icon {
        line-height: 24px;
        float: right;
        font-size: 14px;
    }

    .ant-layout-sider {
        position: fixed;
        height: 100vh;

        background-color: #1B2226;

        .ant-menu {
            background: none;
        }

        .ant-menu-item:first-child {
            margin-top: 0;
        }
    }

    .ant-layout-header {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
    }

    .ant-layout-header, .ant-layout-content {
        margin-left: 300px;
    }

    .ant-layout-header {
        width: calc(100% - 300px);
    }

    .ant-layout-content {
        margin-top: 64px;
        background-color: $white;
    }

    .sider-title, .ant-menu-item, .ant-menu-item a {
        color: $gray-2;
    }

    .sider-title {
        height: 64px;
        line-height: 64px;
        text-align: center;
        border-bottom: 1px solid;
    }

    .ant-menu {
        color: #FFFFFF;
    }

    .ant-menu-item-selected {
        background-color: #006bb5;

        &,
        a {
            color: #fff;
        }

        &:after {
            border: none;
        }
    }

    .ant-menu-inline {
        border-right: none;
    }

    .ant-menu-item {
        width: 100%;

        & :after {
            right: 1px;
        }
    }

    .ant-layout-header {
        background: #006BB5;

        .buttons {
            float: right;

            button {
                border: none;
                background: none;
                color: $white;
            }
        }
    }

    .ant-layout-content {
        padding: 40px;
    }

    .sharoushi-registration-card {
        width: 100%;
    }

    .dashboard-content {
        .title {
            font-size: 20px;
            font-weight: bold;
        }

        .section-title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .change-password {
            color: #01A0DD;
            text-decoration: underline;
            margin-bottom: 45px;
            cursor: pointer;
        }

        .card-container {
            display: flex;
            padding: 15px;
        }

        .ant-input-search-button {
            border: 1px solid $gray-3 !important;
            background-color: white;
        }

        .card-container, .card-element {
            width: 100%;
            border-radius: 3px;

            &.dark-gray {
                border: 1px solid $gray-4;
                background: rgba(196, 196, 196, 0.4);
                color: $gray-4;
            }

            &.purple {
                border: 1px solid $purple-3;
                background: $purple-1;
                color: $purple-3;
            }

            &.gray {
                border: 1px solid $gray-3;
                background: $gray-2;
            }

            &.light-blue {
                border: 1px solid $light-blue-2;
                background: $light-blue-1;
            }

            &.orange {
                border: 1px solid $orange-3;
                background: $orange-1;
            }

            &.green {
                border: 1px solid $green-2;
                background: $green-1;
            }

            &.red {
                border: 1px solid $red-2;
                background: $red-1;
            }

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        .custom-table {
            .action-buttons {
                float: none;

                .ant-btn {
                    min-width: 100px;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }

                .edit-button {
                    background: $light-blue-1;
                    color: $button-primary-color;
                    border: 1px solid $button-primary-color;
                }

                button.delete-button {
                    background: $red-1;
                    color: $red-3;
                    border: 1px solid $red-3;
                }
            }

            .ant-table {
                background: none;
            }

            .ant-table-thead > tr > th {
                border-bottom: 2px solid $gray-6;
                background: none;
            }

            .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
                display: none;
            }

            .ant-table-tbody > tr > td {
                border-bottom: none;
                padding: 5px 16px;
            }

            &.highlighted .ant-table-row {
                background-color: $gray-1;
                border: 1px solid rgba(196, 196, 196, 0.4);
            }
        }
    }

    .profile {
        .edit-button {
            width: 100px;
            float: right;
        }

        .upload-preview {
            border: 1px solid #D9D9D9;
            border-radius: 5px;
            padding: 5px;
            height: 100px;
            min-width: 100px;
        }
    }

    .header-container {
        display: flex;
    }

    .page-actions {
        margin-left: auto;

        &-items {
            width: 250px;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .comment { 
        .ant-form-item-required::before {
            display: none !important;
        }
    }
    
    .consultation-details {
        &-in-charge {
            margin: 20px 0;

            .label {
                font-weight: bold;
                margin: 10px 0;
            }
        }

        .action-buttons {
            margin-top: 30px;

            &_cancel {
                margin-right: 20px;
                width: 200px;
            }

            &_send {
                width: 200px;
            }
        }
    }

    .dashboard-content.profile {
        .form-container {
            width: 30%;
        }
    }

    .header-container {
        display: flex;
    }

    .page-actions {
        margin-left: auto;

        &-items {
            width: 250px;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}
