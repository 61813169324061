@import 'src/assets/styles/variables';

.labor-social-navbar {
    top: 0;
    padding: 5px 136px;
    display: flex;
    width: 100%;
    z-index: 100;
    background-color: $button-primary-color;
    height: 75px;

    .title {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-self: center;
        font-size: 24px;
        color: $button-secondary-color;
    }

    .main-logo {
        height: 45px;
        align-self: center;
        width: max-content;
    }

    .sub-title {
        line-height: 25px;
        font-size: 12px;
        color: #ffff;
        letter-spacing: 1px;
    }
}