.login-form-container {
  .login {
    background-color: white;
    height: auto;
    padding: 50px 13%;
    width: 600px;
  }

  .title {
    color: #000000;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
  }

  .sub-title {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
  }

  .link {
    padding: 10px 0;
  }
}

.login-form-container {
    float: none;
    margin: 25vh auto;
    display: flex;
    align-items: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  height: inherit;
}

.register-button {
  width: 125px;
  align-self: flex-end;
  margin: 5px 0;
}